import DialogContent from '@mui/material/DialogContent';
import withStyles from '@mui/styles/withStyles';

const ModalCardContent = withStyles({
  root: ({ disableOverflowContent = false }) => ({
    color: '#65657B',
    fontSize: '.9em',
    padding: 0,
    paddingRight: disableOverflowContent ? 24 : 0,
    lineHeight: '21px',
    overflow: !disableOverflowContent && 'visible',
  }),
})(DialogContent);

export { ModalCardContent };
