import { useState, useEffect } from 'react';
import MaskedInput from 'react-text-mask';

/** Чем короче префикс, тем ближе его ставить к концу */
const prefixes = ['+998', '+966', '+971', '+7'];

const defaultMask = [
  '+',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const phoneMask = (code) => {
  if (code === prefixes[3])
    return [
      ...code.split(''),
      ' ',
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
    ];

  return [
    ...code.split(''),
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ];
};

function SomePhoneMaskedInput(props) {
  const [mask, setMask] = useState(defaultMask);

  const resolvePhone = (value) => {
    if (value.length === 0) {
      setMask(defaultMask);
      return;
    }

    for (let i = 0; i < prefixes.length; i++) {
      if (
        value.replace(/[\_]/g, '').length >= prefixes[i].length &&
        value.slice(0, prefixes[i].length) === prefixes[i]
      ) {
        setMask(phoneMask(prefixes[i]));

        break;
      }
    }
  };

  const handlerOnChange = (event) => {
    let value = event?.target?.value;

    const clearData = value.replace(/[\_]/g, '');
    if (clearData === '+8') {
      value = value.replace('+8', '+7');
    }

    resolvePhone(value);

    props.form.setFieldValue(props.name, value);
  };

  useEffect(() => {
    const value = !props.value?.startsWith('+') ? `+${props.value}` : props.value;
    resolvePhone(value);
    props.form.setFieldValue(props.name, value);
  }, [props]);

  return (
    <MaskedInput
      {...props}
      keepCharPositions
      placeholder="+0 000 000 00 00"
      mask={mask}
      onChange={handlerOnChange}
    />
  );
}

const validateSomePhone = (value) => {
  if (!value || typeof value !== 'string') return true;

  return value.replace(/[\_]/g, '').length === value.length;
};

export { SomePhoneMaskedInput, validateSomePhone };
