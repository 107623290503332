import {
  $isCollapsed,
  switchMenuCollapse,
  $isFixed,
  switchMenuFixed,
  $screenY,
  setScreenY
} from './navbar.model';

$screenY.on(setScreenY, (_, screenY) => screenY);

$isCollapsed
  .on(switchMenuCollapse, (_, value) => value)
  .on(switchMenuFixed, (state) => !state);
$isFixed.on(switchMenuFixed, (state) => !state);
