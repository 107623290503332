import ym from 'react-yandex-metrika';
import { createStore, createEvent, combine, restore } from 'effector';
import { Badge } from '@mui/material';
import { enviroment } from '@consts/common';
import { menuConfig, visibilityConfig } from '@consts/navigation-menu';
import { $notifications, mapEventNameToGetEntityIdFn, history } from '@features/common';

const $menu = createStore(menuConfig);
const $menuWithNotificationBadge = combine(
  $menu,
  $notifications,
  (menu, notifications) => ({
    items: menu.items.map((i) => {
      if (!i.link) {
        return i;
      }
      const unreadNotificationsForSection = notifications
        .filter((n) => n.read_at === null && n.related_section === i.link)
        .reduce(
          (acc, i) => ({
            ...acc,
            [mapEventNameToGetEntityIdFn[i.event](i)]: i,
          }),
          {}
        );

      const countOfUnreadNotificationForSection = Object.keys(
        unreadNotificationsForSection
      ).length;

      const Icon = i.icon;
      return {
        ...i,
        icon: () => (
          <Badge
            max={99}
            color="primary"
            variant="dot"
            badgeContent={countOfUnreadNotificationForSection}
          >
            <Icon />
          </Badge>
        ),
      };
    }),
  })
);
const $showInNav = createStore(visibilityConfig);
const $isCollapsed = createStore(true);
const $isFixed = createStore(false);
const $screenY = createStore(0);

const currentPageChanged = createEvent();
const switchMenuCollapse = createEvent();
const switchMenuFixed = createEvent();
const setScreenY = createEvent();

const $currentPage = restore(
  currentPageChanged,
  getPageHeader(history.location.pathname, menuConfig.items)
);

history.listen((location) => {
  const { title, back } = getPageHeader(location.pathname, menuConfig.items);
  currentPageChanged({ title, back });

  if (enviroment !== 'development') {
    ym('hit', location.pathname, { title });
  }
});

function getPageHeader(path, routes) {
  const flatConfig = routes.flatMap((i) => (i.nest ? i.items : i));

  const found =
    flatConfig.find((item) => '/' + item.link === path) ||
    flatConfig.find((item) => match(item.link, path));

  return {
    title: found ? found.text : '',
    back:
      found && found.back
        ? typeof found.back === 'function'
          ? found.back
          : () => history.goBack()
        : null,
  };
}

function match(link, path) {
  const splittedPath = path.split('/');

  return splittedPath[1] === link;
}

export {
  $showInNav,
  $menuWithNotificationBadge,
  $currentPage,
  $isCollapsed,
  $isFixed,
  currentPageChanged,
  switchMenuCollapse,
  switchMenuFixed,
  $screenY,
  setScreenY
};
