import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '../../components/listbox';
import { withControl } from '../../hoc/withControl';
import { PropertiesForRentControlGate, $data, $pending } from './model';
import { Props, PropertyForRent } from './types';

export const PropertiesForRentControl = withControl<Props>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    isError,
    enterpriseId = null,
    parentAreaGuid = null,
    areaType = null,
    excludeOptions = [],
  }) => {
    useGate(PropertiesForRentControlGate, { enterpriseId, parentAreaGuid, areaType });

    const [data, pending] = useUnit([$data, $pending]);

    const optionKey =
      enterpriseId && parentAreaGuid && areaType
        ? `${enterpriseId}-${parentAreaGuid}-${areaType}`
        : null;

    const options = optionKey ? data[optionKey] ?? [] : [];

    const findedValue =
      value !== '' ? options?.find((item: PropertyForRent) => item.id === value) : null;

    const handleChange = (_: unknown, newValue: any) => setValue(newValue?.id || null);

    const handleFilterOptions = (
      opts: PropertyForRent[],
      { inputValue }: { inputValue: string }
    ) => {
      const currentOptions = excludeOptions.length
        ? opts.reduce(
            (acc: PropertyForRent[], option) =>
              !excludeOptions.includes(option.id) ? [...acc, option] : acc,
            []
          )
        : opts;

      if (inputValue) {
        return currentOptions.filter((opt) =>
          opt?.label?.toUpperCase().includes(inputValue.toUpperCase())
        );
      }

      return currentOptions;
    };

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          filterOptions={handleFilterOptions}
          loading={pending}
          value={!data ? null : findedValue || null}
          options={options}
          onChange={handleChange}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                error: isError,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
