import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import NavItem from './navItem';
import { setScreenY } from '../models';

const useStyles = makeStyles({
  navList: {
    display: 'grid',
    alignContent: 'start',
    backgroundColor: 'white',
    heigth: '100%',
    zIndex: 2,
    // --- скрываем скролл ---
    overflow: 'auto',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  // ---
});

function NavList(props) {
  const { items = [], isCollapsed, screenY } = props;
  const refNavList = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    if (!isCollapsed) {
      if (refNavList.current !== null) {
        refNavList.current.scrollTo(0, screenY);
      }
    }
    return () => {
      if (!isCollapsed) {
        setScreenY(refNavList.current.scrollTop);
        refNavList.current.scrollTo(0, 0);
      }
    }
  }, [isCollapsed]);


  return (
    <nav className={classes.navList} ref={refNavList}>
      {items.map((item) => <NavItem {...item} key={`i-${item?.text}`} />)}
    </nav>
  );
}

NavList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string.isRequired,
      icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      nest: PropTypes.bool,
    })
  ).isRequired,
};

export default NavList;
