import { createEffect, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { signout } from '@features/common';
import * as api from './api';
import {
  GetPropertiesForRentPayload,
  GetPropertiesForRentResponse,
  Props,
  PropertyForRent,
} from './types';

export const PropertiesForRentControlGate = createGate<Props>();

export const $data = createStore<{ [key: string]: PropertyForRent[] }>({});
export const $pending = createStore<boolean>(false);

const fxGetPropertiesForRent = createEffect<
  GetPropertiesForRentPayload,
  GetPropertiesForRentResponse,
  Error
>().use(api.getPropertiesForRent);

$data
  .on(fxGetPropertiesForRent.done, (state, { params, result }) => {
    if (!Array.isArray(result?.areaList)) {
      return state;
    }

    const objects = {
      [`${params.enterprise_id}-${params.parent_area_guid}-${params.area_types[0]}`]:
        result.areaList.map((object) => ({
          id: object.guid,
          label: object.title ?? '',
        })),
    };

    return { ...state, ...objects };
  })
  .reset(signout);

$pending.on(fxGetPropertiesForRent.pending, (_, pending) => pending).reset(signout);

sample({
  clock: PropertiesForRentControlGate.state,
  source: PropertiesForRentControlGate.status,
  filter: (status, { enterpriseId, parentAreaGuid, areaType }) =>
    Boolean(status && enterpriseId && parentAreaGuid && areaType),
  fn: (_, { enterpriseId, parentAreaGuid, areaType }) => ({
    enterprise_id: enterpriseId as number,
    parent_area_guid: parentAreaGuid as string,
    area_types: [areaType as string],
  }),
  target: fxGetPropertiesForRent,
});
